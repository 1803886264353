import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';

import '../scss/global.scss';
import { CATLoader, CATProvider } from '../libs/shopifyToken';
import { getAccessToken, setAccessToken } from '../libs/accessToken';
import { useApollo } from '../libs/apollo';
import { useEffect, useState } from 'react';
import Layout from '../components/Layout';

// This default export is required in a new `pages/_app.js` file.
const App = ({ Component, pageProps, err }) => {
  const { serverAccessToken } = pageProps;
  const [load, setLoad] = useState(false);

  const apolloClient = useApollo(pageProps.initialApolloState, serverAccessToken);
  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);

  useEffect(() => {
    if (!serverAccessToken || getAccessToken()) {
      return;
    }

    setAccessToken(serverAccessToken);
    setLoad(true);
  });

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <CATProvider>
          <CATLoader load={load}>{getLayout(<Component {...pageProps} err={err} />, pageProps.modal)}</CATLoader>
        </CATProvider>
      </ApolloProvider>
    </>
  );
};
export default App;
