import { memo, useState } from 'react';
import cc from 'classcat';
import Logo from '@outdoor-voices/ui/dist/components/icons/Logo';

import { SCROLL, SHOPIFY_URL } from '../../config/constants';
import css from './index.module.scss';
import scrollTop from '../../utils/dom/scrollTop';
import useEventListener from '../../hooks/useEventListener';

const Header: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);

  useEventListener(SCROLL, () => {
    const top = scrollTop();
    if (top > 0 && !scrolled) {
      setScrolled(true);
    } else if (top === 0 && scrolled) {
      setScrolled(false);
    }
  });

  return (
    <header className={cc([css.root, { [css.scrolled]: scrolled }])}>
      <div className={css.inner}>
        <a className={css.logo} href={SHOPIFY_URL}>
          <Logo />
        </a>
      </div>
    </header>
  );
};

export default memo(Header);
