export const ACCESS_TOKEN_SECRET = process.env.ACCESS_TOKEN_SECRET;
export const APPLE_CALLBACK_ABSOLUTE = `${process.env.NEXT_PUBLIC_SITE_URL}auth/apple/callback`;
export const APPLE_CALLBACK_URL = '/auth/apple/callback';
export const APPLE_CLIENT_ID = process.env.APPLE_CLIENT_ID;
export const APPLE_KEY = process.env.APPLE_KEY;
export const APPLE_KEY_ID = process.env.APPLE_KEY_ID;
export const APPLE_LOGIN_URL = '/auth/apple/login';
export const APPLE_TEAM_ID = process.env.APPLE_TEAM_ID;

const COOKIE_PREFIX = process.env.NEXT_PUBLIC_COOKIE_PREFIX;
export const COOKIES = {
  CAT: `${COOKIE_PREFIX}-cat`,
  JWT: `${COOKIE_PREFIX}-jwt`,
  PROGRAM: 'ov-program',
  PROGRAM_LOADED: 'ov-program-loaded',
  REDIRECT: `${COOKIE_PREFIX}-redirect`,
  REFRESH: `${COOKIE_PREFIX}-refresh`,
};

export const GOOGLE_CALLBACK_ABSOLUTE = `${process.env.NEXT_PUBLIC_SITE_URL}auth/google/callback`;
export const GOOGLE_CALLBACK_URL = '/auth/google/callback';
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_ID_APPS = process.env.GOOGLE_CLIENT_ID_APPS;
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;
export const GOOGLE_LOGIN_URL = '/auth/google/login';
export const IOS_APP_CLIENT_ID = process.env.IOS_APP_CLIENT_ID;
export const JWT_EXPIRES_IN = '15m';
export const MULTIPASS_TOKEN_URL = '/auth/multipass-token';
export const MULTIPASS_URL = '/auth/multipass';
export const NEXT_PUBLIC_COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
export const NODE_ENV = process.env.NODE_ENV;
export const REFRESH_EXPIRES_IN = '7d';
export const REFRESH_TOKEN_SECRET = process.env.REFRESH_TOKEN_SECRET;
export const REFRESH_URL = '/auth/refresh-token';
export const REFRESH_URL_ABSOLUTE = `${process.env.NEXT_PUBLIC_SITE_URL}auth/refresh-token`;
export const VERIFICATION_EXPIRES_IN = '1d';
export const VERIFICATION_TOKEN_SECRET = process.env.VERIFICATION_TOKEN_SECRET;

export const IS_PRODUCTION = NODE_ENV === 'production';
