import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { ReactElement, useEffect, useState } from 'react';
import { Footer, Nav, TopBanner } from '@outdoor-voices/ui';
import cc from 'classcat';
import jwtDecode from 'jwt-decode';
import SVGs from '@outdoor-voices/ui/dist/components/icons/SVGs';

import { COOKIES } from '@ov-id/auth/constants';
import { EMS_GRAPHQL_URI, SHOPIFY_URL, SITE_URL } from '../../config/constants';
import { getAccessToken } from '../../libs/accessToken';
import css from './index.module.scss';
import Header from '../Header';
import NProgress from '../NProgress';

type NavItemType = {
  href: string;
  title: string;
  links?: NavItemType[];
};

type NavResponseType = {
  mobile: NavItemType[];
  main: NavItemType[];
};

type Props = {
  children: ReactElement | JSX.Element[];
  loggedIn?: boolean;
  modal?: boolean;
};

type Token = {
  firstName: string;
};

const Layout: React.FC<Props> = ({ children, modal = false, loggedIn = false }) => {
  const [nav, setNav] = useState(null);
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    if (modal) {
      return;
    }
    const getNav = async (): Promise<NavResponseType> => {
      const url = 'https://www.outdoorvoices.com/';
      const request = await fetch(`${url}pages/nav`);
      const response = (await request.json()) as NavResponseType;
      setNav(response);
      return response;
    };
    getNav();

    if (loggedIn) {
      const token = getAccessToken();
      setFirstName((jwtDecode(token) as Token).firstName);
    }
  }, [modal]);

  return (
    <>
      <a href="#main-content" className={css.skip}>
        Skip to main content
      </a>
      <div className={css.root}>
        <NProgress />
        <SVGs />
        {!modal && (
          <>
            <TopBanner apiURL={EMS_GRAPHQL_URI} jwtCookieName={COOKIES.JWT} />
            {!nav && <Header />}

            {nav && (
              <div className={cc(['ov-nav-wrap', css.navWrap])}>
                <Nav
                  apiURL={EMS_GRAPHQL_URI}
                  firstName={firstName}
                  loggedIn={loggedIn}
                  nav={nav}
                  ovIdURL={SITE_URL}
                  shopURL={SHOPIFY_URL}
                />
              </div>
            )}
          </>
        )}
        {children}
        {!modal && <Footer shopURL={SHOPIFY_URL} />}
      </div>
    </>
  );
};

export const getLayout = (page, modal: boolean, loggedIn: boolean) => (
  <Layout loggedIn={loggedIn} modal={modal}>
    {page}
  </Layout>
);

export default Layout;
