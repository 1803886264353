export const API_CDN = process.env.NEXT_PUBLIC_API_CDN;
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
export const EMS_GRAPHQL_URI = process.env.NEXT_PUBLIC_EMS_GRAPHQL_URI;
export const EVENTS_SITE_URL = process.env.NEXT_PUBLIC_EVENTS_SITE_URL;
export const GRAPHQL_URI = process.env.NEXT_PUBLIC_GRAPHQL_URI;
export const IMG_SERVICE_URL = process.env.NEXT_PUBLIC_IMG_SERVICE_URL;
export const S3_BUCKET = process.env.NEXT_PUBLIC_S3_BUCKET;
export const SHOPIFY_URL = process.env.NEXT_PUBLIC_SHOPIFY_URL;
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

export const BREAKS = {
  TABLET: 1279,
  TABLET_PORTRAIT: 1023,
  MOBILE_LARGE: 767,
  MOBILE: 575,
};

export const FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
export const KEY_DOWN = 'keydown';
export const KEY_CODES = {
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  END: 'End',
  ENTER: 'Enter',
  ESC: 'Escape',
  HOME: 'Home',
  TAB: 'Tab',
};
export const RESIZE = 'resize';
export const SCROLL = 'scroll';
export const LOGIN_URL = '/users/login';
export const MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];
export const ROUTE_EVENTS = {
  START: 'routeChangeStart',
  COMPLETE: 'routeChangeComplete',
  ERROR: 'routeChangeError',
};
export const SHOPPING_PREFERENCES = [
  { value: 'Female', label: 'Women' },
  { value: 'Male', label: 'Men' },
  { value: 'Both', label: 'Both' },
];

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
