import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';

import { REFRESH_URL_ABSOLUTE } from '@ov-id/auth/constants';
import { getAccessToken, isTokenExpired, setAccessToken } from './accessToken';
import { GRAPHQL_URI } from '../config/constants';

const apolloClient = (serverAccessToken: string = null): ApolloClient<NormalizedCacheObject> => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    const accessToken = getAccessToken() || serverAccessToken;
    if (accessToken) {
      operation.setContext({
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      });
    }
    return forward(operation);
  });

  const refreshOptions = {
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      const accessToken = getAccessToken();
      return accessToken === undefined || !isTokenExpired(accessToken);
    },
    fetchAccessToken: () =>
      fetch(REFRESH_URL_ABSOLUTE, {
        credentials: 'include',
        method: 'POST',
      }),
    handleFetch: (newAccessToken) => {
      setAccessToken(newAccessToken);
    },
    handleError: (err) => {
      console.warn('Your refresh token is invalid. Try to login');
      console.error('apolloClient Error:', err);
      setAccessToken('');
    },
  };

  const tokenRefreshLink = new TokenRefreshLink(refreshOptions);
  const headers = {
    'Content-Type': 'application/json',
  };

  const emsLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_EMS_GRAPHQL_URI,
    headers,
  });
  const eventsLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_EVENTS_SITE_GRAPHQL_URI,
    headers,
  });
  const shopifyLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_SHOPIFY_GRAPHQL_URI,
    credentials: 'same-origin',
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPITY_STOREFRONT_ACCESS_TOKEN,
      'Content-Type': 'application/json, application/graphql',
    },
  });

  const ovIDLink = ApolloLink.from([
    tokenRefreshLink as any,
    authMiddleware,
    onError(({ operation, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.warn(`[GraphQL error]: ${GRAPHQL_URI} ${operation.operationName}`);
        console.warn(`[GraphQL errors]:`, graphQLErrors);
        graphQLErrors.forEach(({ message, locations }) => {
          console.warn(`[GraphQL error]: ${message}`);
          locations.forEach((location) => console.warn('location:', location));
        });
      }
      if (networkError) {
        console.warn(`[Network error]: ${GRAPHQL_URI} ${networkError}`);
      }
    }),
    new HttpLink({
      uri: GRAPHQL_URI,
      credentials: 'include',
      headers,
    }),
  ]);

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.split(
      (operation) => operation.getContext().client === 'shopify',
      shopifyLink,
      ApolloLink.split(
        (operation) => operation.getContext().client === 'ems',
        emsLink,
        ApolloLink.split((operation) => operation.getContext().client === 'events', eventsLink, ovIDLink),
      ),
    ),
    cache: new InMemoryCache({}),
  });
  return client;
};
export default apolloClient;
