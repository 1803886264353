import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { COOKIES } from '@ov-id/auth/constants';
import { COOKIE_DOMAIN, IS_PRODUCTION } from '../config/constants';

type AccessPayload = {
  exp: number;
};

export const getAccessToken = (): string => cookie.get(COOKIES.JWT);

export const setAccessToken = (accessToken: string): void => {
  const opts = { expires: 7, domain: COOKIE_DOMAIN };
  if (accessToken === '') {
    cookie.remove(COOKIES.JWT, opts);
    return;
  }
  cookie.set(COOKIES.JWT, accessToken, { ...opts, secure: IS_PRODUCTION });
};

export const isTokenExpired = (token?: string): boolean => {
  if (!token) {
    return true;
  }

  try {
    const { exp } = jwtDecode(token) as AccessPayload;
    const now = Date.now();
    return now >= exp * 1000;
  } catch {
    return true;
  }
};
