import NProgress from 'nprogress';
import React, { useEffect, useRef } from 'react';
import Router from 'next/router';

const routeChangeStart = 'routeChangeStart';
const routeChangeComplete = 'routeChangeComplete';
const routeChangeError = 'routeChangeError';

const Progress: React.FC = () => {
  const showAfterMs = 300;
  const color = '#fc3';
  const timer = useRef(null);

  useEffect(() => {
    const onRouteChangeStart = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(NProgress.start, showAfterMs);
    };

    const onRouteChangeEnd = () => {
      clearTimeout(timer.current);
      NProgress.done();
    };

    Router.events.on(routeChangeStart, onRouteChangeStart);
    Router.events.on(routeChangeComplete, onRouteChangeEnd);
    Router.events.on(routeChangeError, onRouteChangeEnd);

    return () => {
      clearTimeout(timer.current);
      Router.events.off(routeChangeStart, onRouteChangeStart);
      Router.events.off(routeChangeComplete, onRouteChangeEnd);
      Router.events.off(routeChangeError, onRouteChangeEnd);
    };
  }, []);
  return (
    <style>
      {`
        #nprogress {
          pointer-events: none;
        }
        #nprogress .bar {
          background: ${color};
          height: 4px;
          left: 0;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 1031;
        }
        #nprogress .peg {
          box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
          display: block;
          height: 100%;
          opacity: 1;
          position: absolute;
          right: 0px;
          -ms-transform: rotate(3deg) translate(0px, -4px);
          -webkit-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
          width: 100px;
        }
        .nprogress-custom-parent {
          overflow: hidden;
          position: relative;
        }
        .nprogress-custom-parent #nprogress .bar {
          position: absolute;
        }
      `}
    </style>
  );
};

export default Progress;
