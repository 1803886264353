import { COOKIES, MULTIPASS_TOKEN_URL } from '@ov-id/auth/constants';
import { createContext, useContext, useEffect, useState } from 'react';
import cookie from 'js-cookie';

import { COOKIE_DOMAIN, IS_PRODUCTION } from '../config/constants';
import { getAccessToken } from './accessToken';
import { useStorefrontAccessTokenWithMultipassMutation } from '../generated/storefront-graphql';

const getCustomerAccessToken = (): string => cookie.get(COOKIES.CAT);

export const setCustomerAccessToken = (accessToken: string): void => {
  const opts = { expires: 30, domain: COOKIE_DOMAIN };
  if (accessToken === '') {
    cookie.remove(COOKIES.CAT, opts);
    return;
  }
  cookie.set(COOKIES.CAT, accessToken, { ...opts, secure: IS_PRODUCTION });
};

// const isTokenExpired = (token?: string): boolean => {
//   if (!token) {
//     return true;
//   }

//   try {
//     return true;
//     // if (Date.now() >= exp * 1000) {
//     //   return false;
//     // } else {
//     //   return true;
//     // }
//   } catch {
//     return false;
//   }
// };

type ContextType = {
  customerAccessToken?: string;
  loadCustomerAccessToken: () => void;
};

const context: ContextType = {
  customerAccessToken: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadCustomerAccessToken: () => {
    console.info('initial');
  },
};

export const CATContext = createContext(context);
export const CATProvider = ({ children }) => {
  const [customerAccessToken, setCAT] = useState(getCustomerAccessToken());
  const [loadCAT] = useStorefrontAccessTokenWithMultipassMutation();

  const loadCustomerAccessToken = async () => {
    const request = await fetch(MULTIPASS_TOKEN_URL, { credentials: 'include', method: 'GET' });
    const response = await request.json();

    if (!response.token) {
      return;
    }

    const {
      data: {
        customerAccessTokenCreateWithMultipass: { customerAccessToken },
      },
    } = await loadCAT({ variables: { multipassToken: response.token }, context: { client: 'shopify' } });
    setCustomerAccessToken(customerAccessToken.accessToken);
    setCAT(customerAccessToken.accessToken);
  };

  useEffect(() => {
    if (!getAccessToken() || customerAccessToken) {
      return;
    }

    loadCustomerAccessToken();
  }, [customerAccessToken]);

  return (
    <CATContext.Provider
      value={{
        customerAccessToken,
        loadCustomerAccessToken,
      }}
    >
      {children}
    </CATContext.Provider>
  );
};

type CATLoaderProps = {
  load: boolean;
};

export const CATLoader: React.FC<CATLoaderProps> = ({ children, load }) => {
  const { customerAccessToken, loadCustomerAccessToken } = useContext(CATContext);

  useEffect(() => {
    if (!getAccessToken() || customerAccessToken) {
      return;
    }
    loadCustomerAccessToken();
  }, [load]);
  return <>{children}</>;
};
